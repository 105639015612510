import {
  USER
} from '../../Config/actionTypes';

const initialState = {
  usuarioNombreUsuario: "",
  usuarioNombreCompleto: "",
  usuarioAvatar: "",
  showFavorites: true,
  token: null,
  error: false,
  errorMessage: "",
  waitForResponse: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER.BEFORE_VALIDATION:
      return {
        ...state,
        waitForResponse: true
      };
    case USER.LOGIN_SUCCESS:
      return {
        ...state,
        usuarioNombreCompleto: action.payload.usuarioNombreCompleto,
          usuarioNombreUsuario: action.payload.usuarioNombreUsuario,
          usuarioAvatar: action.payload.usuarioAvatar,
          waitForResponse: false,
          error: false,
          errorMessage: ""
      };
    case USER.LOAD_USER_INFO:
      return {
        ...state,
        usuarioNombreCompleto: action.payload.usuarioNombreCompleto,
          usuarioAvatar: action.payload.usuarioAvatar
      }
      case USER.ERROR:
        return {
          ...state,
          waitForResponse: false,
            error: action.payload.error,
            errorMessage: action.payload.errorMessage
        };
      case USER.INITIAL_STATE:
        return {
          ...state, ...initialState
        };
      case USER.HIDE_FAVORITES:
        //console.log('reducer.js -- 51 >  === HIDE_FAVORITES');
        return {
          ...state,
          showFavorites: false
        };
      case USER.SHOW_FAVORITES:
        //console.log('reducer.js -- 57 >  === SHOW_FAVORITES');
        return {
          ...state,
          showFavorites: true
        };
      default:
        return state;
  }
}